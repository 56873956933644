<template>
  <UBanner
    v-if="!loading"
  >
    <article
      class="box"
    >
      <h1 class="page-title">
        {{ localDocumentTitle }}
      </h1>

      <NewsFilter
        v-model="news"
        @change="value => filteredNews = value"
      />

      <div v-if="news.length">
        <VRow v-if="filteredNews.length">
          <VCol
            v-for="singleNews in filteredNews"
            :key="singleNews.order_id"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
          >
            <SingleNewsCard
              :single-news="singleNews"
              :to="getTo(singleNews)"
              refreshable
            />
          </VCol>
        </VRow>

        <p v-else>
          По данным параметрам не удалось найти курсы.
        </p>
      </div>

      <UPlaceholder v-else />
    </article>
  </UBanner>
  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import NewsFilter from '@components/NewsFilter.vue'
import SingleNewsCard from '@/components/SingleNewsCard.vue'
import documentTitle from '@/mixins/documentTitle'
import UPlaceholder from '@components/UPlaceholder.vue'
import UBanner from '@components/UBanner.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

export default {
  name: 'TheNews',

  components: {
    UPlaceholder,
    NewsFilter,
    SingleNewsCard,
    UBanner,
  },

  mixins: [
    documentTitle
  ],

  data () {
    return {
      documentTitle: '$sdo.lc_menu_news.two',
      filteredNews: [],
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      news: getters.NEWS
    })
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      getNews: actions.GET_NEWS
    }),

    getTo (singleNews) {
      return { name: 'singleNews', params: { singleNewsID: singleNews.id } }
    },

    async register () {
      try {
        await this.getNews()
        this.filteredNews = this.news.sort((a, b) => b.is_promoted - a.is_promoted)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
