<template>
  <article
    v-if="!loading"
    class="box single-news"
  >
    <h1 class="page-title">
      {{ news.title }}
      <VChip
        v-if="news.is_promoted"
        small
        class="single-news__chip accent text-uppercase"
      >
        Важно
      </VChip>
    </h1>

    <VCard>
      <VCardText class="single-news__content">
        <img
          v-if="news.image"
          class="single-news__img"
          :src="'/' + news.image"
          alt=""
        >
        <div
          class="rte-content"
          v-html="news.body"
        />
      </VCardText>
      <VCardText>{{ formatDateTime(news.created_at) }}</VCardText>
    </VCard>
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getSingleNews } from '@/api/api'
import { UDate } from '@/utils/date'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

export default {
  name: 'TheSingleNews',

  props: {
    singleNews: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      news: {},
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
    }),
  },

  watch: {
    $route: {
      handler: async function (to) {
        this.loading = true

        try {
          const { news, now } = await getSingleNews(to.params.singleNewsID)
          this.getCountNewNews()
          this.updateNow(now)
          this.news = news || {}
        } catch (err) {
          this.createNotification({
            type: 'error',
            msg: err.message
          })
        } finally {
          this.loading = false
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions({
      getCountNewNews: actions.GET_COUNT_NEW_NEWS,
      createNotification: actions.CREATE_NOTIFICATION,
      updateNow: actions.UPDATE_NOW
    }),

    formatDateTime: UDate.formatDateTime,
  },
}
</script>

<style lang="scss">
.single-news__chip {
  position: relative;
  top: -8px;
  left: -4px;
  display: inline-block;
}

.single-news__img {
  display: block;
  margin: 0 auto 1rem;
  max-height: 300px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

.single-news__content {
  display: inline-block;
  color: unset !important;
}
</style>
